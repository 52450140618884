export default [
    {
        component: 'dining',
        label: '用餐时间',
        prev: 1,
        max: 2,
        key: 'meal_time',
        placeholder: '请选择用餐时间',
        rules: [
            {required: true, message: '请选择用餐时间'}
        ],
    },
    {
        component: 'input',
        type: 'number',
        label: '用餐次数',
        key: 'meal_count',
        suffix: '次',
        noGap: true,
        disabled: true,
        rules: [
            {required: true, message: '请输入用餐次数'}
        ],
    },
    {
        component: 'input',
        type: 'number',
        label: '用餐人数',
        key: 'meal_people',
        noGap: true,
        suffix: '人',
        placeholder: '请输入用餐人数',
        rules: [
            {required: true, message: '请输入用餐人数'}
        ],
    },
]
