import {volunStatusOptions} from "../../hr/volunteer";
import {clone} from "../tool";

const groupItem = {
    component: 'select',
    mode: 'selector',
    label: '注册小组',
    placeholder: '选择志工后自动填充',
    key: 'volunteer_group_id',
    noGap: true,
    rangeKey: "label",
    valueKey: "value",
    disabled: true,
    group: true,
    options: [],
    rules: [{
        required: true,
        message: '请选择志工'
    }]
};

const statusItem = {
    component: 'select',
    mode: 'selector',
    label: '原注册状态',
    placeholder: '选择志工后自动填充',
    key: 'register_status',
    noGap: true,
    rangeKey: "label",
    valueKey: "value",
    disabled: true,
    options: [...volunStatusOptions],
    rules: [{
        required: true,
        message: '请选择志工'
    }]
}

const baseItems = [
    {
        component: 'volunteer',
        label: '志工姓名',
        key: 'volunteer',
        register_status: 1,
        rules: [{
            required: true,
            message: '请选择志工'
        }],
        listen: ['tel', 'id_card', 'volunteer_group_id', 'register_status']
    },
    {
        component: 'input',
        label: '手机号码',
        key: 'tel',
        noGap: true,
        disabled: true,
        placeholder: '选择志工后自动填充',
        rules: [{
            required: true,
            message: '请选择志工'
        }],
    },
    {
        component: 'input',
        label: '证件号码',
        key: 'id_card',
        noGap: true,
        disabled: true,
        placeholder: '选择志工后自动填充',
        rules: [{
            required: true,
            message: '请选择志工'
        }],
    },
];

export default {
    status: [
        ...baseItems,
        {...clone(groupItem)},
        {...clone(statusItem)},
        {
            ...clone(statusItem),
            key: "status",
            label: "申请状态",
            placeholder: '请选择申请状态',
            rules: [{
                required: true,
                message: '请选择申请状态'
            }]
        }
    ],
    group: [
        ...baseItems,
        {
            ...clone(statusItem),
            label: "注册状态"
        },
        {
            ...clone(groupItem),
            label: "原注册小组",
        },
        {
            ...clone(groupItem),
            key: "apply_group_id",
            label: "转入小组",
            placeholder: '请选择申请转入小组',
            disabled: false,
            rules: [{
                required: true,
                message: '请选择申请转入小组'
            }],
        },
    ]
};
