<template>
  <div class="content md flex-box vertical">
    <a-space>
      <open-data type="userName" :openid="id" class="leave-user"></open-data>
      <div class="leave-user-dept" v-if="dept">部门：<open-data type="departmentName" :openid="dept"></open-data></div>
    </a-space>
    <data-list
      ref="dataList"
      class="flex-grow"
      entity-name="请假记录"
      hide-show-detail
      hide-edit
      hide-delete
      :url="url"
      :query="query"
      :custom-url="customUrl"
      :add-btn="null"
      :action-width="80"
      :action="action"
      :columns="columns">
      <template v-slot:query="{form}">
        <a-form-model-item label="假期类型">
          <a-select
            style="width: 180px"
            placeholder="选择假期类型筛选"
            :options="holidayList"
            allow-clear
            v-model="form.holiday_id"
            @change="updateList">
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="申请时间">
          <a-range-picker
            v-model="form.time"
            :placeholder="['开始时间', '结束时间']"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @change="updateList"/>
        </a-form-model-item>
      </template>
      <template v-slot:action="{item}">
        <span class="txt-btn" @click="showDetail(item)">查看</span>
      </template>
    </data-list>
  </div>
</template>
<script>
    import {getStaffTypeName} from "../../common/hr/book";
    import {getApplyStatusText} from "../../common/js/apply";

    export default {
        name: "PersonLeave",
        data() {
            return {
                dept: "",
                url: "/admin/leave",
                columns: [
                    { title: "假期类型", dataIndex: "leave_type" },
                    { title: "申请时间", dataIndex: "apply", customRender: text => text ? text.create_time: "--" },
                    { title: "状态", dataIndex: "status", customRender: (text, record) => record.apply ? getApplyStatusText(record.apply.status) : "--"},
                ],
                holidayList: []
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            },
            id() {
                return this.$route.params.user;
            },
            query() {
                const id = this.id;
                let res = `&sort=-id&expand=apply&filter[user_id]=${id}`;
                return res;
            }
        },
        watch: {
            id() {
                this.getQyUserInfo();
            }
        },
        created() {
            this.getQyUserInfo();
            this.getHolidayList();
        },
        methods: {
            showDetail(record) {
                if(record.apply) {
                    this.$store.commit("setApply", record.apply);
                } else {
                    this.$info({
                        title: "提示",
                        content: "该申请记录已删除"
                    });
                }
            },
            customUrl(form) {
                let res = "";
                if(form.time && form.time.length == 2) {
                    res += `&filter[create_time][gte]=${form.time[0]} 00:00:00&filter[create_time][lte]=${form.time[1]} 23:59:59`;
                }
                if(form.holiday_id) {
                    res += `&&filter[holiday_id]=${form.holiday_id}`;
                }
                return res;
            },
            getEvaluateUserInfo() {
                return this.$axios(`/admin/evaluate-user?filter[wx_user_id]=${this.id}`).then(res => {
                    if(res.data.length > 0) {
                        return res.data[0]
                    } else {
                        return null;
                    }
                });
            },
            getHolidayList() {
                this.getEvaluateUserInfo().then(user => {
                    let url = "/admin/holiday?pageSize=1000&filter[h_type]=1";
                    const type = user?.type;
                    if(type) {
                        url += `&filter[type]=${type}`;
                    }
                    this.$axios(url).then(res => {
                        const list = res.data.map(item => {
                            // 查指定身份类型成员的假期时不显示假期类型的适用范围
                            const title = type ? item.name : `${item.name}-${getStaffTypeName(item.type)}`;
                            return { key: item.id, title };
                        });
                        this.holidayList = list;
                    });
                })
            },
            updateList() {
                this.$refs.dataList.getList();
            },
            getQyUserInfo() {
                const id = this.id;
                if(id) {
                    const url = `/admin/common/get-user?id=${id}`;
                    this.$axios({
                        url,
                        noTempleFilter: true
                    }).then(res => {
                        if(res.error == 0) {
                            this.dept = res.data.main_department;
                        }
                    });
                }
            }
        }
    }
</script>

<style lang="less">
.leave-user {
  font-size: 16px;
  font-weight: bold;
}
</style>
