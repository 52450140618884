export default {
    leave: [
        {
            component: 'select',
            mode: 'selector',
            label: '请假类型',
            placeholder: '请选择',
            key: 'leave_type',
            rangeKey: "name",
            options: [],
            rules: [
                {required: true, message: '请选择请假类型'}
            ]
        },
        {
            component: 'half-date',
            label: '开始时间',
            placeholder: '请选择',
            key: 'start_time',
            rules: [
                {required: true, message: '请选择开始时间'}
            ]
        },
        {
            component: 'half-date',
            label: '结束时间',
            placeholder: '请选择',
            key: 'end_time',
            noGap: true,
            rules: [
                {required: true, message: '请选择结束时间'}
            ]
        },
        {
            component: 'input',
            type: 'number',
            label: '请假天数',
            key: 'day_num',
            suffix: '天',
            noGap: true,
            disabled: true,
            rules: [
                {required: true, message: '请输入请假天数'}
            ],
        },
    ],
    monk_leave: [{
        component: 'select',
        mode: 'selector',
        label: '请假类型',
        placeholder: '请选择',
        key: 'leave_type',
        rangeKey: "name",
        options: [],
        show_in_list: true,
        rules: [{
            required: true,
            message: '请选择请假类型'
        }]
    }],
    trip: [
        {
            component: 'half-date',
            label: '开始时间',
            placeholder: '请选择',
            key: 'start_time',
            show_in_list: true,
            rules: [{
                required: true,
                message: '请选择开始时间'
            }]
        },
        {
            component: 'half-date',
            label: '结束时间',
            placeholder: '请选择',
            key: 'end_time',
            show_in_list: true,
            noGap: true,
            rules: [{
                required: true,
                message: '请选择结束时间'
            }]
        },
    ],
    overtime: [
        {
            component: 'date-time',
            label: '开始时间',
            placeholder: '请选择',
            key: 'start_time',
            show_in_list: true,
            rules: [{
                required: true,
                message: '请选择开始时间'
            }]
        },
        {
            component: 'date-time',
            label: '结束时间',
            placeholder: '请选择',
            key: 'end_time',
            show_in_list: true,
            noGap: true,
            rules: [{
                required: true,
                message: '请选择结束时间'
            }]
        },
        {
            component: 'input',
            type: 'digit',
            label: '加班时长',
            key: 'duration',
            suffix: '小时',
            noGap: true,
            disabled: true,
            rules: [{
                required: true,
                message: '请输入加班时长'
            }],
        },
    ],
    out: [
        {
            component: 'date-time',
            label: '开始时间',
            placeholder: '请选择',
            key: 'start_time',
            show_in_list: true,
            rules: [{
                required: true,
                message: '请选择开始时间'
            }]
        },
        {
            component: 'date-time',
            label: '结束时间',
            placeholder: '请选择',
            key: 'end_time',
            show_in_list: true,
            noGap: true,
            rules: [{
                required: true,
                message: '请选择结束时间'
            }]
        },
        {
            component: 'input',
            type: 'digit',
            label: '外出时长',
            key: 'duration',
            suffix: '小时',
            noGap: true,
            disabled: true,
            rules: [{
                required: true,
                message: '请输入外出时长'
            }],
        },
    ]
}
