<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    entity-name="请假记录"
    :add-btn="null"
    hide-show-detail
    hide-edit
    hide-delete
    row-key="user_id"
    :custom-url="customUrl"
    :width="1168"
    :action-width="80"
    :action="action"
    :columns="columns"
    :default-query="defaultQuery"
    :request-config="{ noTempleFilter: true }"
    :search-key-type="{year: 2, type: 2, name: 2}">
    <template v-slot:query="{form}">
      <a-form-model-item label="统计年份">
        <a-select
          style="width: 180px"
          placeholder="选择年份筛选"
          :options="yearList"
          v-model="form.year"
          @change="handleYearChange">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="身份">
        <a-select
          style="width: 180px"
          placeholder="选择身份筛选"
          :options="staffTypes"
          allow-clear
          v-model="form.type"
          @change="updateList">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="姓名/法名">
        <a-input-search v-model="form.name" placeholder="输入姓名/法名查询" @search="updateList"></a-input-search>
      </a-form-model-item>
    </template>
    <template v-slot:action="{item}">
      <span class="txt-btn" @click="showDetail(item)">查看</span>
    </template>
  </data-list>
</template>

<script>
    import { staffTypes, getStaffTypeName } from "../../common/hr/book";

    export default {
        name: "LeaveRecord",
        data() {
            return {
                loading: false,
                url: "/admin/leave/list",
                staffTypes,
                year: 0,
                yearList: [],
                holidayList: [],
                defaultQuery: {}
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            },
            columns() {
                let res = [
                    { title: '年份', width: 80, customRender: () => this.year + "年" },
                    { title: '成员', width: 100, dataIndex: 'name', customRender: (text, record) => text ? text : <open-data  type="userName`" openid={record.user_id}/> },
                    { title: '身份', width: 80, dataIndex: 'type', customRender: getStaffTypeName },
                ];
                const holidayList = this.holidayList;
                const normal = [], sz = [];
                holidayList.forEach(h => {
                   if(h.h_type == 1) {
                       normal.push(h);
                   } else {
                       sz.push(h);
                   }
                });
                if(normal.length > 0) {
                    const ids = normal.map(item => item.id);
                    res.push({ title: '请假总时长（天）', width: 160, customRender: record => this.getTotalCount(record, ids) });
                    normal.forEach(h => {
                        const title = `${h.name}-${getStaffTypeName(h.type)}（天）`;
                        res.push({ title, width: title.length * 14 + 32, customRender: record => (record.count || {})[h.id] || 0 })
                    });
                }
                if(sz.length > 0) {
                    const ids = sz.map(item => item.id);
                    res.push({ title: '僧职告假（次）', width: 140, customRender: record => this.getTotalCount(record, ids) });
                    sz.forEach(h => {
                        const title = `${h.name}（次）`;
                        res.push({ title, width: title.length * 14 + 32, customRender: record => (record.count || {})[h.id] || 0 })
                    });
                }
                return res;
            }
        },
        created() {
            const now = new Date();
            const year = now.getFullYear();
            const start = 2022; // 最早开始年份
            this.yearList = new Array(year - start + 1).fill(year).map((y, i) => Object({key: y - i, title: y - i + "年"}));
            this.defaultQuery = {year};
            this.year = year;
            this.getHolidayList();
        },
        methods: {
            showDetail(record) {
                this.$router.push({name: "PersonLeave", params: {user: record.user_id}})
            },
            handleYearChange(val) {
                this.year = val;
                this.updateList();
            },
            customUrl(form) {
                let res = "";
                if(form.year) {
                    res += `&start_time=${form.year}-01-01 00:00:00&end_time=${form.year}-12-31 23:59:59`;
                }
                if(form.type) {
                    res += `&type=${form.type}`;
                }
                if(form.name) {
                    res += `&name=${form.name}`;
                }
                return res;
            },
            getHolidayList() {
                const url = '/admin/holiday?pageSize=1000';
                this.$axios(url).then(res => {
                    this.holidayList = res.data;
                });
            },
            getTotalCount(record, ids) {
                let res = 0;
                const count = record.count;
                if(count) {
                    res = Object.keys(count).reduce((acc, val) => {
                        if(ids.indexOf(val * 1) >= 0) {
                            acc += count[val];
                        }
                        return acc;
                    }, res);
                }
                return res;
            },
            updateList() {
                this.$refs.dataList.getList();
            },
        }
    }
</script>

<style lang="less">
.holiday-over {
  color: @error-color;
}
</style>
